<template>
  <div>
    <StatFilter PERIOD="daily" :STORE_OPTS="branch_list" :QUEUE_OPTS="queue_list" @query="onQueryBI" />
    <CRow v-if="load_done">
      <CCol md="6" lg="3">
        <CardSimpleNumber :header="$t('cloud.title.queue_count')" :text="`${qCnt}`">
          &nbsp;
        </CardSimpleNumber>
      </CCol>
      <CCol md="6" lg="3">
        <CardSimpleNumber :header="$t('cloud.title.queue_person')" :text="`${total_waiting}`">
          &nbsp;
        </CardSimpleNumber>
      </CCol>
      <CCol md="6" lg="3">
        <CardSimpleNumber :header="$t('cloud.title.queue_served')" :text="`${transaction_count}`">
          <h4 :class="{'text-success':transaction_count_increase >= 0,'text-danger':transaction_count_increase<0}">
            <svgicon :name="transaction_count_increase >= 0 ? 'arrow_drop_up':'arrow_drop_down'" width="23" height="23" />
            <span>{{Math.sqrt(transaction_count_increase*transaction_count_increase)}}</span>
          </h4>
        </CardSimpleNumber>
      </CCol>
      <CCol md="6" lg="3">
        <CardSimpleNumber :header="$t('cloud.title.queue_avg_service_time')" :text="calcAvgTransaction">
          <h4 :class="{'text-success':avg_transaction_time < 200,'text-warning':avg_transaction_time>=200 && avg_transaction_time < 400,'text-danger':avg_transaction_time>=400}">
            <svgicon v-if="avg_transaction_time < 200" name="sentiment_very_satisfied" width="34" height="34" />
            <svgicon v-if="avg_transaction_time < 400 && avg_transaction_time >=200" name="sentiment_neutral" width="34" height="34" />
            <svgicon v-if="avg_transaction_time >= 400" name="sentiment_very_dissatisfied" width="34" height="34" />
            <span v-if="avg_transaction_time < 200">{{$t('cloud.title.queue_short_waiting')}}</span>
            <span v-if="avg_transaction_time < 400 && avg_transaction_time >=200">{{$t('cloud.title.queue_normal_waiting')}}</span>
            <span v-if="avg_transaction_time >= 400">{{$t('cloud.title.queue_long_waiting')}}</span>
          </h4>
        </CardSimpleNumber>
      </CCol>
      <CCol md="12" lg="6">
        <CardBarCharts
          :title="$t('cloud.title.queue_avg_length')"
          :stats="[stats_retail_queue, stats_retail_queue_yesterday]"
          :labels="[$t('cloud.title.day_today'), $t('cloud.title.day_yesterday')]"
          :colors="['#2586a4', '#e65054']"
        />
      </CCol>
      <CCol md="12" lg="6">
        <CardBarCharts
          :title="$t('cloud.title.queue_served_hour')"
          :stats="[stats_retail_queue_count_enter, stats_retail_queue_count_exit]"
          :labels="[$t('cloud.title.queue_enter'), $t('cloud.title.queue_exit')]"
          :colors="['#358ec4', '#f0c33c']"
        />
      </CCol>
    </CRow>
    <div v-loading="!load_done" v-if="!load_done" />
  </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import StatFilter from './StatFilter.vue'
import CardSimpleNumber from '@/components/cards/SimpleNumber.vue'
import CardBarCharts from '@/components/cards/BarCharts.vue'

export default {
  name: 'RetailQueueAnalytics',

  components: {
    StatFilter,
    CardSimpleNumber,
    CardBarCharts
  },

  data() {
    return {
      load_done: false,
      loading: {
        counter: true,
        branch: true,
        queue: true,
        transaction: true
      },
      filters: null,
      branch_list: [],
      queue_list: [],
      transaction_count: 0,
      transaction_count_increase: 0,
      avg_transaction_time: 0,
      avg_transaction_time_increase: 0,
      total_waiting: 0,
      stats_retail_queue: [],
      stats_retail_queue_yesterday: [],
      stats_retail_queue_count_enter: [],
      stats_retail_queue_count_exit: []
    }
  },
  computed: {
    calcAvgTransaction() {
      const mm = Math.floor(this.avg_transaction_time / 60);
      const ss = this.avg_transaction_time % 60;
      if (mm > 0) return `${mm}m ${ss}s`;
      return ss+'s';
    },
    storeCnt() {
      if (this.branch_list) return this.branch_list.length;
      return 0;
    },
    qCnt() {
      if (this.queue_list) return this.queue_list.length;
      return 0;
    }
  },
  mounted() {
    this.initFetch();
  },
  methods: {
    initFetch() {
      this.getCounterList();
      this.getBranchList();
    },
    getCounterList() {
      let query_string = '';
      const params = {
        ipp: 500,
        search: this.searchText,
        page: this.pageCurrent,
      };
      if (this.filters && this.filters.branch) {
        params.branch = this.filters.branch;
      }
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      this.loading.counter = true;
      axios.get(`/api/bi/queues/${query_string}`)
        .then(result => {
          this.queue_list = result.data.list;
        })
        .catch(e => {
          console.error(e);
        })
        .finally(_ => {
          this.loading.counter = false;
          this.checkLoading();
        })
    },
    getBranchList() {
      let query_string = '';
      const params = {
        // sort: 'created_at',
        // order: 'desc',
        // search: this.searchText,
        ipp: 500, // FIXME. 난중에 하자...
        page: 1,
        opt: 'branch',
        geo_info: 0
      };
      if (params) {
        query_string = '?' + qs.stringify(params)
      }
      this.loading.branch = true;
      axios.get(`/api/bi/stores/${query_string}`)
        .then(result => {
          this.branch_list = result.data.list;
          // this.pageTotal = result.data.page.total;
          // this.pageCurrent = result.data.page.current;
          // this.count = result.data.page.count;
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          this.loading.branch = false;
          this.checkLoading();
        })
    },
    onQueryBI(stat_filter) {
      this.load_done = false;
      this.filters = stat_filter;
      if (this.filters && this.filters.date) {
        this.getQueueTransaction();
        this.statQueue();
      }
      this.getCounterList();
    },
    buildBIQs() {
      let query_string = '';
      const params = {};
      if (this.filters) {
        params.branch = this.filters.branch;
        if (this.filters.queue) params.counter = this.filters.queue;
        if (this.filters.date) {
          this.filters.date.setHours(0);
          this.filters.date.setMinutes(0);
          this.filters.date.setSeconds(0);
          this.filters.date.setMilliseconds(0);
          params.sts = this.filters.date.getTime();
          params.ets = params.sts + (1000 * 60 * 60 * 24);
        }
      }
      if (params) {
        query_string = '?' + qs.stringify(params);
      }
      return query_string;
    },
    statQueue() {
      const query_string = this.buildBIQs();

      this.loading.queue = true;
      axios.get(`/api/bi/statistics/queue/${query_string}`)
        .then(result => {
          this.stats_retail_queue = result.data[0].map(el => {
            const val = el.abs + el.bias_pos - el.bias_neg;
            const created_at = el.created_at;
            return {value: val, x: created_at};
          });
          this.stats_retail_queue_yesterday = result.data[1].map(el => {
            const val = el.abs + el.bias_pos - el.bias_neg;
            const created_at = el.created_at;
            return {value: val, x: created_at};
          });
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          this.loading.queue = false;
          this.checkLoading();
        })
    },
    getQueueTransaction() {
      const query_string = this.buildBIQs();
      let loading_trans = true;
      let loading_count = true;

      this.loading.transaction = true;
      axios.get(`/api/bi/statistics/queue-transaction/${query_string}`)
        .then(result => {
          this.transaction_count = result.data.transaction_count;
          this.transaction_count_increase = result.data.transaction_count_increase;
          this.avg_transaction_time = result.data.avg_transaction_time;
          this.total_waiting = result.data.total_waiting;
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          loading_trans = false;
          if (!loading_count) {
            this.loading.transaction = false;
            this.checkLoading();
          }
        })
      axios.get(`/api/bi/statistics/queue-transaction-count/${query_string}`)
        .then(result => {
          this.stats_retail_queue_count_enter = result.data.map(el => {
            const val = el.count_enter;
            const created_at = el.created_at;
            return {value: val, x: created_at};
          });
          this.stats_retail_queue_count_exit = result.data.map(el => {
            const val = el.count_exit;
            const created_at = el.created_at;
            return {value: val, x: created_at};
          });
        })
        .catch(error => {
          console.error(error);
        })
        .finally(_ => {
          loading_count = false;
          if (!loading_trans) {
            this.loading.transaction = false;
            this.checkLoading();
          }
        })
    },
    checkLoading() {
      if (!this.loading.branch && !this.loading.counter && !this.loading.queue && !this.loading.transaction) {
        setTimeout(() => {
          this.load_done = true;
        }, 100);
      }
    }
  }
}
</script>